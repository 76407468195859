'use client';

import React, { FC, JSX, useEffect, useState } from 'react';
import Image from '@/components/image/Image';
import { ApiProductsCacheData, ApiProductsCacheRequestFromJSON } from '@ibe/api';
import Keys from '@/Translations/generated/da/productTeaser.json.keys';
import { useTranslation } from '@/app/i18n/client';
import {
  getDefaultFormatPrice,
  getProductPageBasePath,
  logger,
  mapToApiImage
} from '@/Util/globals';
import Button from '@/components/Button';
import { THEMES } from '@/Util/globals';
import { getCheapestProduct } from '@/components/Search/helpersProduct';
import { ApiProduct } from '../../../api/model';
import GridContainer from '@/Layouts/GridContainer';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { useApi } from '@/Hooks/useApi';
import { EditableAreaOrPageChild } from '@/types/cms/magnolia';
import { Link } from '@/Hooks/useReactTransitionProgress';

interface TopProductTeaserProps extends EditableAreaOrPageChild {
  product: ApiProduct;
  numberOfPacificProducts: number;
  theme?: THEMES;
}

const TopProductTeaser: FC<TopProductTeaserProps> = ({
  product,
  theme,
  pageProps
}: TopProductTeaserProps): JSX.Element => {
  const { t } = useTranslation('topProductTeaser');
  const locale = useCurrentLanguage();
  const { rootNodePath, siteConfig, isAuthor } = useGlobalMGLProps() || {};
  const api = useApi(isAuthor);
  const { teaserImageMedium, teaserImageLarge, tagline, displayUrl, productTitle } = product;
  const teaserImage = mapToApiImage(teaserImageLarge || teaserImageMedium);
  const [cacheProduct, setCacheProduct] = useState<ApiProductsCacheData | undefined>(undefined);
  const { duration, startingPriceAmount } = cacheProduct || {};

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const productPackage = await api.getProductsList(
          ApiProductsCacheRequestFromJSON({
            productCodes: product.isManualTour ? [] : [product.name],
            manualProductCodes: product.isManualTour ? [product.name] : undefined,
            languageCode: locale,
            allProducts: true
          })
        );
        const cheapestProduct = !!productPackage?.[0]
          ? getCheapestProduct(productPackage[0])
          : undefined;
        if (!!cheapestProduct) {
          setCacheProduct(cheapestProduct);
        }
      } catch {
        logger('error')('Unable to fetch products data for TopProductTeaser');
      }
    })();
  }, [product]);

  return (
    <GridContainer className="top-product-teaser__container">
      <Link
        className="top-product-teaser"
        href={`/${getProductPageBasePath(rootNodePath || '', siteConfig)}/${displayUrl}`}
      >
        {!!teaserImage && (
          <Image
            image={teaserImage}
            width={960}
            height={540}
            serverSideContext={pageProps?.albConfig.context}
          />
        )}
        <div className={`top-product-teaser__content ${theme || THEMES.blue}`}>
          <div className="top-product-teaser__pacific">
            <div className="top-product-teaser__pacific__infos">
              {!!duration && <div>{t(Keys.day, { count: duration })}</div>}
              <div className="top-product-teaser__divider">|</div>
              {!!locale && !!startingPriceAmount && (
                <div>{`${t(Keys.from)} ${getDefaultFormatPrice(startingPriceAmount, locale)}`}</div>
              )}
            </div>
          </div>
          <p className="top-product-teaser__title">{productTitle}</p>
          <p className="top-product-teaser__description">{tagline}</p>
          <div className="top-product-teaser__button">
            <Button color="primary" showCaret>
              {t(Keys.goToTravel)}
            </Button>
          </div>
        </div>
      </Link>
    </GridContainer>
  );
};

export default TopProductTeaser;
