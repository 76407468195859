/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-02-10 15:48:25 */

const Keys = {
  temperature: 'temperature',
  percipitation: 'percipitation',
  january: 'january',
  february: 'february',
  march: 'march',
  april: 'april',
  may: 'may',
  june: 'june',
  july: 'july',
  august: 'august',
  september: 'september',
  october: 'october',
  november: 'november',
  december: 'december'
}; export default Keys;