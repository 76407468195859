/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-02-10 15:48:25 */

const Keys = {
  generalForHoldHeadline: 'generalForHoldHeadline',
  sectionHeader1: 'sectionHeader1',
  sectionBody1: 'sectionBody1',
  sectionHeader2: 'sectionHeader2',
  sectionBody2: 'sectionBody2',
  sectionHeader3: 'sectionHeader3',
  sectionBody3: 'sectionBody3',
  sectionHeader4: 'sectionHeader4',
  sectionBody4: 'sectionBody4',
  sectionHeader5: 'sectionHeader5',
  sectionBody5: 'sectionBody5',
  sectionHeader6: 'sectionHeader6',
  sectionBody6: 'sectionBody6',
  sectionHeader7: 'sectionHeader7',
  sectionBody7: 'sectionBody7',
  sectionHeader8: 'sectionHeader8',
  sectionBody8: 'sectionBody8',
  sectionHeader9: 'sectionHeader9',
  sectionBody9: 'sectionBody9',
  sectionHeader10: 'sectionHeader10',
  sectionBody10: 'sectionBody10',
  sectionHeader11: 'sectionHeader11',
  sectionBody11: 'sectionBody11',
  sectionHeader12: 'sectionHeader12',
  sectionBody12: 'sectionBody12',
  sectionHeader13: 'sectionHeader13',
  sectionBody13: 'sectionBody13'
}; export default Keys;