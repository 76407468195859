import { FormConfig } from '@ibe/components';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { facCalendarSmall } from '@/Theme/SVG/Icons';
import { TFunction } from 'i18next';
import { CheckboxCheck } from '@/Theme/SVG/Svgs';
import React from 'react';

export const getNotesFormConfig = (t: TFunction) => {
  return {
    rows: [
      {
        key: 'row2',
        elements: [
          {
            tag: 'textarea',
            key: 'notes',
            label: t(Keys.notes),
            boldLabel: true,
            subLabel: t(Keys.notesSubLabel),
            inCollapse: true,
            initialValue: '',
            rows: 4,
            rowGrid: {
              lg: 6
            },
            validations: {
              schema: 'string',
              types: []
            }
          }
        ]
      }
    ]
  } as FormConfig;
};

const getTravelerFormConfig = (
  t: TFunction,
  {
    isFirstTraveler,
    isChild,
    adultMinAge,
    onRoyalAlbBlur,
    participantIndex
  }: {
    isFirstTraveler: boolean;
    isChild: boolean;
    adultMinAge: number;
    onRoyalAlbBlur: () => Promise<void>;
    participantIndex: number;
  }
): FormConfig => {
  return {
    rows: [
      {
        key: 'row1',
        elements: [
          {
            tag: 'input',
            key: 'email',
            placeholder: t(Keys.email),
            initialValue: '',
            onBlur: onRoyalAlbBlur,
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.emailError)
                },
                {
                  type: 'email',
                  customMessage: t(Keys.emailError)
                }
              ]
            },
            rowGrid: {
              lg: 6
            }
          },
          {
            key: 'external0',
            tag: 'external',
            childIndex: 0,
            rowGrid: {
              xs: 0,
              lg: 6
            }
          },
          {
            tag: 'input',
            key: 'confirmEmail',
            placeholder: t(Keys.confirmEmail),
            initialValue: '',
            onBlur: onRoyalAlbBlur,
            disablePaste: true,
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.emailError)
                },
                {
                  type: 'email',
                  customMessage: t(Keys.emailError)
                },
                {
                  type: 'equalEmails',
                  customMessage: t(Keys.confirmEmailError)
                }
              ]
            },
            rowGrid: {
              lg: 6
            }
          },
          {
            key: 'external1',
            tag: 'external',
            childIndex: 1,
            rowGrid: {
              xs: 0,
              lg: 1
            }
          },
          {
            tag: 'input',
            key: 'royalAlbatros',
            label: t(Keys.royalAlbLabel),
            placeholder: t(Keys.royalAlbPlaceholder),
            initialValue: '',
            onBlur: onRoyalAlbBlur,
            className: 'royal-albatros-input',
            rowGrid: {
              lg: 4
            }
          },
          {
            tag: 'select',
            key: 'title',
            label: t(Keys.title),
            placeholder: t(Keys.titlePlaceholder),
            noMenuPortal: true,
            externalDataOptions: 'salutations',
            rowGrid: {
              lg: 3
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.titleError)
                }
              ]
            }
          },
          {
            tag: 'input',
            key: 'firstName',
            placeholder: t(Keys.firstName),
            initialValue: '',
            rowGrid: {
              lg: 4
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.firstNameError)
                }
              ]
            }
          },
          {
            tag: 'input',
            key: 'lastName',
            placeholder: t(Keys.lastName),
            initialValue: '',
            rowGrid: {
              lg: 5
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.lastNameError)
                }
              ]
            }
          },
          {
            tag: 'datePicker',
            key: 'birthDate',
            label: t(Keys.birthDate),
            placeholder: t(Keys.birthDatePlaceholder),
            icon: facCalendarSmall,
            rowGrid: {
              lg: 3
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.birthDateError)
                },
                ...(isFirstTraveler
                  ? [
                      {
                        type: 'isOlderThanAgeOnDateString',
                        customMessage: t(Keys.mustBeEqualOrOlderError, { age: adultMinAge })
                      }
                    ]
                  : []),
                ...(isChild
                  ? [
                      {
                        type: 'isYoungerThanAgeOnDateString',
                        customMessage: t(Keys.mustBeEqualOrYoungerError, { age: adultMinAge })
                      }
                    ]
                  : [])
              ]
            }
          },
          {
            tag: 'input',
            key: 'street',
            placeholder: t(Keys.street),
            initialValue: '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.streetError)
                }
              ]
            },
            rowGrid: {
              lg: 6
            }
          },
          {
            tag: 'inputSearchDropdown',
            key: 'zipCode',
            externalDataOptions: 'postalCodes',
            placeholder: t(Keys.zipCode),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.zipCodeError)
                },
                {
                  type: 'customRegExValidation',
                  parameter: /^\S+$/,
                  customMessage: t(Keys.noSpacesAllowedInZipCodeError)
                }
              ]
            },
            rowGrid: {
              lg: 3
            }
          },
          {
            tag: 'input',
            key: 'city',
            placeholder: t(Keys.city),
            initialValue: '',
            className: 'col-xxl-3',
            rowGrid: {
              lg: 4
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.cityError)
                }
              ]
            }
          },
          {
            tag: 'select',
            key: 'country',
            label: t(Keys.country),
            placeholder: t(Keys.countryPlaceholder),
            className: 'col-xxl-4',
            noMenuPortal: true,
            externalDataOptions: 'countries',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.countryError)
                }
              ]
            },
            rowGrid: {
              lg: 5
            }
          },
          {
            key: 'external2',
            tag: 'external',
            className: 'col-xxl-0',
            childIndex: 2,
            rowGrid: {
              xs: 0,
              lg: 3
            }
          },
          {
            tag: 'input',
            key: 'countryCode',
            placeholder: t(Keys.countryCode),
            initialValue: '',
            className: 'col-xxl-2',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.countryCodeError)
                },
                {
                  type: 'customRegExValidation',
                  parameter: /\+(?!00)\d+/,
                  customMessage: t(Keys.countryCodeError)
                }
              ]
            },
            rowGrid: {
              xs: 4,
              lg: 3
            }
          },
          {
            tag: 'input',
            key: 'phone',
            placeholder: t(Keys.phone),
            initialValue: '',
            className: 'col-xxl-3',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.phoneError)
                },
                {
                  type: 'customRegExValidation',
                  parameter: /^([0-9]{3,})$/,
                  customMessage: t(Keys.phoneNumberError)
                }
              ]
            },
            rowGrid: {
              xs: 8,
              lg: 6
            }
          },
          {
            key: 'external3',
            tag: 'external',
            className: 'col-xxl-0',
            childIndex: 3,
            rowGrid: {
              xs: 0,
              lg: 3
            }
          },
          {
            id: `newsletter-${participantIndex}`,
            tag: 'input',
            type: 'checkbox',
            key: 'newsletter',
            customCheckboxElement: (
              <div className="checkbox__field">
                <CheckboxCheck />
              </div>
            ),
            label: t(Keys.subscribeToNewsletter),
            rowGrid: {
              lg: 5
            }
          }
        ]
      }
    ]
  } as FormConfig;
};

export default getTravelerFormConfig;
