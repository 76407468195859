import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import {
  ApiAccommodation,
  ApiAccommodationFromJSON,
  ApiExtension,
  ApiImageFromJSON
} from '../../../../api/model';
import { ApiExtraService } from '@ibe/api';
import Image from '@/components/image/Image';
import { default404Image } from '@ibe/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facDuration } from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { API_ITEM_SERVICE_CODE, getPrice } from '@/Util/globals';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import Button from '@/components/Button';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import RelatedServices from '@/components/checkout/extensions/RelatedServices';
import TruncateContent from '@/components/truncateContent/TruncateContent';
import { Props } from '@/types/cms/magnolia';

const Extension: FC<{
  checkoutStore: CheckoutStore;
  extension: ApiExtraService;
  extensionSelected?: ApiExtraService;
  extensionId: string;
  isSelected: boolean;
  isDisabled: boolean;
  setSelectedExtensionAsAccommodation: Dispatch<SetStateAction<ApiAccommodation | undefined>>;
  pageProps?: Props;
}> = observer(function Extension({
  checkoutStore,
  extension,
  extensionSelected,
  extensionId,
  isSelected,
  isDisabled,
  setSelectedExtensionAsAccommodation,
  pageProps
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const locale = useCurrentLanguage();
  const { price, extraUnits } = extension;
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const cmsExtra = useMemo(() => {
    return extension.metaInfo?.contentByMetaType?.extraContent?.content?.[extension.cmsCode] as
      | ApiExtension
      | undefined;
  }, [extension]);

  const onTruncate = (isTruncated: boolean): void => {
    setIsTruncated(isTruncated);
  };

  const onRequest = useMemo(() => {
    return extension.extraUnits.every(extraUnit => extraUnit.availability === 0);
  }, [extension]);

  const handleSelectClick = async (): Promise<void> => {
    if (!!extraUnits?.[0]?.unitRates?.[0]) {
      if (isSelected) {
        await checkoutStore.deselectItemsInCart(extensionId, [extraUnits[0].unitRates[0].id]);
      } else {
        await checkoutStore.selectItemsInCart(extensionId, [extraUnits[0].unitRates[0].id]);
      }
    }
  };

  return (
    <div className="extension__outer">
      <div className="extension">
        {!!cmsExtra?.mainImage ? (
          <Image
            image={cmsExtra.mainImage}
            className="extension__image"
            serverSideContext={pageProps?.albConfig.context}
          />
        ) : (
          <Image
            className="extension__image"
            image={ApiImageFromJSON({
              imageLink: default404Image,
              title: 'default image',
              caption: 'default image',
              width: 100,
              height: 100
            })}
            serverSideContext={pageProps?.albConfig.context}
          />
        )}
        <div className="extension__content">
          <div className="extension__content__left">
            {!!cmsExtra?.duration && (
              <div className="extension__content__duration">
                <FontAwesomeIcon icon={facDuration} />
                <span>{t(Keys.day, { count: cmsExtra.duration })}</span>
              </div>
            )}
            <div className="extension__content__name">{cmsExtra?.name || extension.name}</div>
            {!!cmsExtra?.teaserDescription && (
              <div className="extension__content__description">
                <TruncateContent onTruncate={onTruncate}>
                  {cmsExtra.teaserDescription}
                </TruncateContent>
              </div>
            )}
            {!!cmsExtra?.teaserDescription && isTruncated && (
              <div
                className="checkout-teaser__read-more"
                onClick={(): void =>
                  setSelectedExtensionAsAccommodation(
                    ApiAccommodationFromJSON({
                      id: extension.id,
                      name: cmsExtra?.name || extension.name,
                      images: !!cmsExtra?.mainImage ? [cmsExtra.mainImage] : [],
                      shortDescription: cmsExtra.teaserDescription,
                      rooms: []
                    })
                  )
                }
              >
                {t(Keys.readMore)}
              </div>
            )}
          </div>
          <div className="extension__content__right">
            <div className="extension__content__right__inner">
              {price?.finalPrice !== undefined &&
                price?.finalPrice !== null &&
                !!price?.currencyCode && (
                  <div className="extension__content__price__container">
                    <div className="extension__content__price">{getPrice(price, locale)}</div>
                  </div>
                )}
              {!!onRequest && <div>{t(Keys.onRequest)}</div>}
              <Button
                className={classNames('extension__select checkout-teaser__button', {
                  'checkout-teaser__button--selected': isSelected,
                  'checkout-teaser__button--disabled': isDisabled
                })}
                color="primary"
                onClick={handleSelectClick}
                disabled={isDisabled}
              >
                <span>{isSelected ? t(Keys.remove) : t(Keys.add)}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {!!extension.relatedServices &&
        extension.relatedServices.filter(
          relatedService => relatedService.code !== API_ITEM_SERVICE_CODE.FLIGHT
        ).length > 0 && (
          <Collapse isOpen={isSelected}>
            <RelatedServices
              checkoutStore={checkoutStore}
              relatedServices={extensionSelected?.relatedServices || []}
              extensionUnitRateId={extraUnits?.[0]?.unitRates?.[0].id}
              extensionId={extensionId}
              pageProps={pageProps}
            />
          </Collapse>
        )}
    </div>
  );
});

export default Extension;
