import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { ApiTransfer } from '@ibe/api';
import {
  ApiAccommodation,
  ApiAccommodationFromJSON,
  ApiExtra,
  ApiImageFromJSON
} from '../../../api/model';
import Image from '@/components/image/Image';
import { default404Image } from '@ibe/components';
import TruncateContent from '@/components/truncateContent/TruncateContent';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { Props } from '@/types/cms/magnolia';

const BusTeaser: FC<{
  bus: ApiTransfer;
  cmsExtra?: ApiExtra;
  setSelectedBusAsAccommodation: Dispatch<SetStateAction<ApiAccommodation | undefined>>;
  pageProps?: Props;
}> = ({ bus, cmsExtra, setSelectedBusAsAccommodation, pageProps }): JSX.Element => {
  const { t } = useTranslation('Checkout');
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const onTruncate = (isTruncated: boolean): void => {
    setIsTruncated(isTruncated);
  };

  return (
    <div className="checkout-teaser__container">
      <div className="checkout-teaser">
        <div className="checkout-teaser__left">
          {!!cmsExtra?.image ? (
            <Image image={cmsExtra.image} serverSideContext={pageProps?.albConfig.context} />
          ) : (
            <Image
              image={ApiImageFromJSON({
                imageLink: default404Image,
                title: 'default image',
                alt: 'default image',
                width: 100,
                height: 100
              })}
              serverSideContext={pageProps?.albConfig.context}
            />
          )}
        </div>
        <div className="checkout-teaser__center">
          <h4>{cmsExtra?.name || bus.name}</h4>
          {!!cmsExtra?.description && (
            <div>
              <TruncateContent onTruncate={onTruncate}>{cmsExtra.description}</TruncateContent>
            </div>
          )}
          {!!cmsExtra?.description && isTruncated && (
            <div
              className="checkout-teaser__read-more"
              onClick={(): void =>
                setSelectedBusAsAccommodation(
                  ApiAccommodationFromJSON({
                    id: bus.id,
                    name: cmsExtra?.name || bus.name,
                    images: !!cmsExtra?.image ? [cmsExtra.image] : [],
                    shortDescription: cmsExtra.description,
                    rooms: []
                  })
                )
              }
            >
              {t(Keys.readMore)}
            </div>
          )}
        </div>
        <div className="checkout-teaser__right" />
      </div>
    </div>
  );
};

export default BusTeaser;
