/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-02-10 15:48:25 */

const Keys = {
  headline: 'headline',
  duration: 'duration',
  buttonText: 'buttonText',
  currency: 'currency',
  hotel: 'hotel',
  priceIncludes: 'priceIncludes',
  bookingInfo: 'bookingInfo',
  staticHotelText: 'staticHotelText',
  title: 'title'
}; export default Keys;