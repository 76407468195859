import React, { Fragment, ReactElement, useCallback, useEffect } from 'react';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import SimpleAccordion from '@/components/accordion/SimpleAccordion';
import { useTransitionMap } from 'react-transition-state';
import { useTranslation } from '@/app/i18n/client';
import GridContainer from '@/Layouts/GridContainer';
import { ApiAccommodation } from '../../../api/model';
import { Props } from '@/types/cms/magnolia';
import Image from '@/components/image/Image';

const AccommodationList = ({
  accommodations,
  theme,
  pageProps
}: {
  accommodations?: ApiAccommodation[];
  theme?: string;
  pageProps?: Props;
}): ReactElement => {
  const { t } = useTranslation('product');
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });

  const { stateMap, toggle, setItem } = useTransitionMap<string>({
    timeout: 500,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    if (!!accommodations && accommodations.length > 0) {
      accommodations.forEach(accommodation => {
        setItem(accommodation.id || '');
      });

      toggle(accommodations[0].id || '');
    }
  }, [accommodations]);

  const handleAccommodationClick = useCallback((id: string) => {
    toggle(id);
  }, []);

  return (
    <GridContainer className={`accommodation ${theme || ''}`}>
      {!isDesktop ? (
        <>
          {accommodations?.[0].accommodationType && (
            <div className="accommodation__headline">
              <h2>{t(accommodations[0].accommodationType)}</h2>
            </div>
          )}
          <SimpleAccordion
            groups={
              accommodations?.map(accommodation => ({
                groupHeader: accommodation?.name || '',
                group: [
                  {
                    sectionHeader: '',
                    sectionText: (
                      <AccommodationContent accommodation={accommodation} pageProps={pageProps} />
                    )
                  }
                ]
              })) || []
            }
          />
        </>
      ) : (
        <>
          {accommodations?.[0].accommodationType && (
            <div className="accommodation__headline">
              <h2>{t(accommodations[0].accommodationType)}</h2>
            </div>
          )}
          <div className="accommodation__container">
            <div className="accommodation__list">
              <div className="accommodation__list__sticky">
                {accommodations?.map(accommodation => (
                  <div
                    key={accommodation.id}
                    className={`accommodation__name${
                      stateMap.get(accommodation.id || '')?.isMounted ? ' active' : ''
                    }`}
                    onClick={() => {
                      if (!stateMap.get(accommodation.id || '')?.isMounted) {
                        handleAccommodationClick(accommodation.id || '');
                      }
                    }}
                  >
                    <h4>{accommodation?.name}</h4>
                  </div>
                ))}
              </div>
            </div>
            <div className="accommodation__content__container">
              {accommodations?.map(accommodation => (
                <Fragment key={accommodation.id}>
                  {stateMap.get(accommodation.id || '')?.status !== 'unmounted' &&
                    stateMap.get(accommodation.id || '')?.isMounted && (
                      <AccommodationContent
                        accommodation={accommodation}
                        className={`${
                          stateMap.get(accommodation.id || '')?.status !== 'preEnter' &&
                          stateMap.get(accommodation.id || '')?.status !== 'exiting'
                            ? 'accommodation__content--visible'
                            : ''
                        }${
                          stateMap.get(accommodation.id || '')?.status === 'exiting'
                            ? ' accommodation__content--exiting'
                            : ''
                        }`}
                      />
                    )}
                </Fragment>
              ))}
            </div>
            <div className="accommodation__list__print">
              {accommodations?.map((accommodation, index) => (
                <Fragment key={accommodation.id}>
                  <Image
                    image={accommodation.images?.[0] || {}}
                    className="accommodation__content__image"
                    serverSideContext={pageProps?.albConfig.context}
                  />
                  <AccommodationContent
                    key={index}
                    accommodation={accommodation}
                    className={'accommodation__content--print'}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </>
      )}
    </GridContainer>
  );
};

export default AccommodationList;
