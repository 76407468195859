'use client';

import React, { FC, PropsWithChildren, JSX, ReactElement } from 'react';
import { DefaultPageProps } from '@/types/cms/magnolia';
import ContactBar from '@/components/contactBar/ContactBar';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { THEMES } from '@/Util/globals';
import ContentModuleDynamic from '@/components/contentModule/ContentModuleDynamic';
import { PageComponentProps } from '@/components/magnoliaPage/componentHelper';
import Intro from '@/components/intro/Intro';
import Image from '@/components/image/Image';
import sanitize from '@/Util/sanitize';

export const ShipPageInner: FC<
  PropsWithChildren<
    DefaultPageProps &
      PageComponentProps & {
        text: string;
        tel: string;
        mail: string;
        themeContactInformation: string;
        showContactBar: boolean;
        pathname: string;
      }
  >
> = (props): JSX.Element => {
  const {
    header,
    footer,
    themeContactInformation,
    showContactBar,
    children,
    upperPageContent,
    pageProps,
    pathname
  } = props;

  const { ship } = pageProps || {};

  const { contentArea, contentAreaTitle, themeContentArea } = ship || {};

  return (
    <div className="country-page">
      <div className="page__top">
        {header && <EditableArea className="page__top__header" content={header} />}
        {showContactBar && <ContactBar theme={themeContactInformation || THEMES.darkGreen} />}
      </div>
      <div className="page__center">
        {upperPageContent}
        {ship && (
          <Intro
            headline={ship.introTitle ? ship.introTitle : ship.name}
            pageProps={pageProps}
            pathname={pathname}
            singleColumn={
              <div className="intro__single-column">
                {ship?.introImage?.imageLink && (
                  <div className="intro__single-column__image intro__single-column__image--right">
                    <Image
                      image={ship.introImage}
                      className="intro__container__image"
                      serverSideContext={pageProps?.albConfig.context}
                    />
                  </div>
                )}
                {!!ship?.introText && (
                  <>
                    {!!ship.teaserShortDescription && (
                      <div
                        className="intro__single-column__text"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(ship.teaserShortDescription, true)
                        }}
                      />
                    )}
                    <div
                      className="intro__single-column__text"
                      dangerouslySetInnerHTML={{ __html: sanitize(ship.introText, true) }}
                    />
                  </>
                )}
              </div>
            }
            theme={ship.themeIntro || THEMES.blue}
          />
        )}
        {!!contentArea && contentArea.length > 0 && (
          <ContentModuleDynamic
            headline={contentAreaTitle}
            theme={themeContentArea}
            contentRow={contentArea}
            pageProps={pageProps}
          />
        )}
        {children}
      </div>
      <div className="page__bottom">{footer && <EditableArea content={footer} />}</div>
    </div>
  );
};

export default ShipPageInner;
