import React, { FC } from 'react';
import { observer } from 'mobx-react';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import GridContainer from '@/Layouts/GridContainer';
import Link from '@/components/Link';
import { ExternalLinks } from '@/components/checkout/TermsAndConditions';
import Checkbox from '@/components/Search/Checkbox';
import {
  OnlinePaymentCaller,
  OnlinePaymentCallerProps
} from '@/templates/checkout/CheckoutPageInnerMarkup';

const InsurancePL: FC<{
  externalLinks: ExternalLinks;
  setIsOnlinePaymentDisabled: (active: boolean, caller: OnlinePaymentCaller) => void;
  onlinePaymentSettingState: OnlinePaymentCallerProps;
}> = observer(function InsurancePL({
  externalLinks,
  onlinePaymentSettingState,
  setIsOnlinePaymentDisabled
}): JSX.Element {
  const { t } = useTranslation('Checkout');

  const handleCheckbox = (caller: 'cancel' | 'additional') => {
    setIsOnlinePaymentDisabled(
      caller === 'cancel'
        ? !onlinePaymentSettingState.cancel
        : !onlinePaymentSettingState.additional,
      caller
    );
  };

  return (
    <GridContainer className="insurancePL">
      <h2 className="checkout__headline">{t(Keys.insurance)}</h2>
      <p dangerouslySetInnerHTML={{ __html: t(Keys.insuranceText) }}></p>
      {!!externalLinks.allianzInsurancePdfUrl && (
        <div>
          <Link href={externalLinks.allianzInsurancePdfUrl} target="_blank">
            {t(Keys.readMore)}
          </Link>
        </div>
      )}
      <div className="checkout__insurancePL__checkbox">
        <Checkbox
          checked={onlinePaymentSettingState.cancel}
          onChange={() => handleCheckbox('cancel')}
        />
        <p onClick={() => handleCheckbox('cancel')}>
          <span>{t(Keys.insuranceCancellation)}</span>
        </p>
      </div>
      <div className="checkout__insurancePL__checkbox">
        <Checkbox
          checked={onlinePaymentSettingState.additional}
          onChange={() => handleCheckbox('additional')}
        />
        <p onClick={() => handleCheckbox('additional')}>
          <span>{t(Keys.insuranceAdditional)}</span>
        </p>
      </div>
      <div className="insurance__footer__supplier">
        <div>{t(Keys.suppliedBy)}</div>
        <img src="/logos/logo_allianz-travel.png" alt="Allianz Travel" />
      </div>
    </GridContainer>
  );
});

export default InsurancePL;
