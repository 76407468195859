import React, { ReactElement, useEffect } from 'react';
import sanitize from '@/Util/sanitize';
import Keys from '@/Translations/generated/da/product.json.keys';
import { useTransition } from 'react-transition-state';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import StarRating from '@/components/starRating/StarRating';
import ImageSlider from '@/components/imageSlider/ImageSlider';
import { useTranslation } from '@/app/i18n/client';
import { ApiAccommodation } from '../../../api/model';
import Image from '@/components/image/Image';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import Link from '@/components/Link';
import { Props } from '@/types/cms/magnolia';
import { useProductPageContext } from '@/components/productPageContext/ProductPageContext';

const AccommodationContent = ({
  accommodation,
  className,
  simpleMode,
  pageProps
}: {
  accommodation?: ApiAccommodation;
  className?: string;
  simpleMode?: boolean;
  pageProps?: Props;
}): ReactElement => {
  const { t } = useTranslation('product');
  const accommodationDescriptionBottom = accommodation?.descriptionBottom;
  const paragraphs = accommodationDescriptionBottom?.split('\n\n');
  const firstParagraph = paragraphs ? paragraphs[0] : '';
  const remainingDescription = paragraphs ? paragraphs.slice(1).join('\n\n') : '';
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const globalProps = useGlobalMGLProps();
  const { printMode } = useProductPageContext();
  const shipsPageUrl =
    globalProps?.siteConfig?.parameters?.shipPage
      ?.replace(globalProps?.rootNodePath, '')
      ?.replace('ship', accommodation?.relatedShip?.metaInformation?.displayUrl || '') || '';

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 500,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    toggle(simpleMode);
  }, []);

  return (
    <div className={`accommodation__content ${className || ''}`}>
      {!!accommodation?.images?.[0]?.imageLink && !printMode && (
        <>
          {simpleMode ? (
            <Image
              image={accommodation.images[0]}
              className="accommodation__content__single-image"
              serverSideContext={pageProps?.albConfig.context}
            />
          ) : (
            <ImageSlider images={accommodation.images} pageProps={pageProps} />
          )}
          <Image
            image={accommodation.images[0]}
            className="accommodation__content__image"
            serverSideContext={pageProps?.albConfig.context}
          />
        </>
      )}

      <div className="accommodation__content__name__container">
        <div className="accommodation__content__name">
          {simpleMode ? <h4>{accommodation?.name}</h4> : <b>{accommodation?.name}</b>}
        </div>
        {!!accommodation?.shortDescription && (
          <div className="accommodation__content__short-description">
            <div
              dangerouslySetInnerHTML={{ __html: sanitize(accommodation.shortDescription, true) }}
            />
          </div>
        )}
        <div className="accommodation__content__wrapper">
          {!!accommodation?.numberOfStars && (
            <div className="accommodation__content__star-rating">
              <StarRating
                rating={accommodation.numberOfStars}
                maxRating={Math.ceil(accommodation.numberOfStars)}
              ></StarRating>
            </div>
          )}
          {!!accommodation?.address && (
            <div className="accommodation__content__address">
              <b>{t(Keys.address)}</b>
              <div dangerouslySetInnerHTML={{ __html: sanitize(accommodation.address, true) }} />
            </div>
          )}
          {!!accommodation?.moreInformation && (
            <div className="accommodation__content__more-information">
              <b>{t(Keys.moreInformation)}</b>
              <div
                dangerouslySetInnerHTML={{ __html: sanitize(accommodation.moreInformation, true) }}
              />
            </div>
          )}
        </div>
        {isDesktop && !!accommodation?.descriptionTopRight && (
          <div className="accommodation__content__description-top-right">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(accommodation.descriptionTopRight, true)
              }}
            ></div>
          </div>
        )}
        {(!!firstParagraph || !!remainingDescription || accommodation?.descriptionTopRight) && (
          <div className="accommodation__content__description">
            <div dangerouslySetInnerHTML={{ __html: sanitize(firstParagraph || '', true) }} />
            {isMounted && (
              <>
                <div
                  className={`accommodation__content__description__remaining${
                    status === 'preEnter' || status === 'exiting'
                      ? ' accommodation__content__description__remaining--hidden'
                      : ''
                  }`}
                  dangerouslySetInnerHTML={{ __html: sanitize(remainingDescription || '', true) }}
                />
                {!isDesktop && (
                  <div className="accommodation__content__description-top-right">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitize(accommodation?.descriptionTopRight || '', true)
                      }}
                    ></div>
                  </div>
                )}
              </>
            )}
            {!simpleMode && !!remainingDescription && (
              <div
                className="accommodation__content__read-more"
                onClick={(): void => toggle(!isMounted)}
              >
                {isMounted ? t(Keys.showLess) : t(Keys.readMore)}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="accommodation__content__wrapper accommodation__button">
        {!!accommodation?.relatedShip && (
          <Link className="btn btn-primary" href={shipsPageUrl} showCaret>
            {t(Keys.goToShip)}
          </Link>
        )}
      </div>
    </div>
  );
};

export default AccommodationContent;
