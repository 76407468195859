import React, { FC, JSX } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { ApiImage, ApiImageFromJSON } from '../../../api/model';
import Image from '@/components/image/Image';
import { default404Image } from '@ibe/components';
import classNames from 'classnames';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { observer } from 'mobx-react';
import { Props } from '@/types/cms/magnolia';

const SeatSelection: FC<{
  checkoutStore: CheckoutStore;
  headline: string;
  image?: ApiImage;
  componentId: string;
  selections: Array<{
    id: string;
    description: string;
    price: string;
    priceRaw: number;
    isSelected: boolean;
  }>;
  pageProps?: Props;
}> = observer(function SeatSelection({
  headline,
  image,
  selections,
  checkoutStore,
  componentId,
  pageProps
}): JSX.Element {
  const { t } = useTranslation('Checkout');

  const handleSelect = async (id: string): Promise<void> => {
    await checkoutStore.selectItemsInCart(componentId, [id]);
  };

  return (
    <div className="seat-selection">
      <h5 className="seat-selection__headline h5-medium">{headline}</h5>
      <div className="seat-selection__selections">
        {selections.map(selection => (
          <div
            key={selection.id}
            className="seat-selection__selection"
            onClick={(): Promise<void> => handleSelect(selection.id)}
          >
            <span>
              <span>{!!selection.priceRaw ? selection.description : t(Keys.seatIncluded)}</span>
              {!!selection.priceRaw && <span>{`+ ${selection.price}`}</span>}
            </span>
            <span
              className={classNames('selection-item__select__button__radio', {
                'selection-item__select__button__radio--active': selection.isSelected
              })}
            />
          </div>
        ))}
      </div>
      <div className="seat-selection__image">
        {!!image ? (
          <Image image={image} useDefaultLoader serverSideContext={pageProps?.albConfig.context} />
        ) : (
          <Image
            image={ApiImageFromJSON({
              imageLink: default404Image,
              title: 'default image',
              caption: 'default image',
              width: 100,
              height: 100
            })}
            serverSideContext={pageProps?.albConfig.context}
          />
        )}
      </div>
    </div>
  );
});

export default SeatSelection;
