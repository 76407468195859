import React, { FC } from 'react';
import Image from '@/components/image/Image';
import classNames from 'classnames';
import { Continents } from '@/components/continentMap/helpers';
import { Props } from '@/types/cms/magnolia';
import { ApiImageFromJSON } from '../../../../api/model';

interface ContinentImageProps {
  name: Continents;
  isActive: boolean;
  pageProps?: Props;
}

const ContinentImage: FC<ContinentImageProps> = ({ name, isActive, pageProps }) => {
  return (
    <Image
      className={classNames('continent-map__map-image', {
        'continent-map__map-image--active': isActive && name !== Continents.WORLD
      })}
      image={ApiImageFromJSON({
        imageLink: process.env.NEXT_PUBLIC_MAPS_IMAGES_URL.replace(
          process.env.NEXT_PUBLIC_MAPS_IMAGES_REPLACEMENT,
          name
        ),
        title: name,
        caption: name,
        width: 1244,
        height: 627
      })}
      priority
      noLoader
      quality={75}
      serverSideContext={pageProps?.albConfig.context}
    />
  );
};

export default ContinentImage;
