'use client';

import React, { useState, MouseEvent, ReactElement, useEffect, Fragment } from 'react';
import { useTransitionMap } from 'react-transition-state';
import { useMediaQuery } from '@ibe/components';
import Image from '@/components/image/Image';
import { mapToApiImage, MEDIAQUERY_DEFAULTS, THEMES } from '@/Util/globals';
import GridContainer from '@/Layouts/GridContainer';
import { ApiHighlight, ApiHighlightFromJSON } from '../../../api/model';
import { EditableAreaOrPageChild, MagnoliaDamNode, MagnoliaNode } from '@/types/cms/magnolia';
import useDelayRender from '@/Hooks/useDelayRender';

type MagnoliaHighlight = MagnoliaNode & {
  text: string;
  image: MagnoliaDamNode;
};

interface Props extends Partial<EditableAreaOrPageChild> {
  title?: string;
  highlights?: ApiHighlight[];
  highlight?: MagnoliaNode & { [key: `highlights${number}`]: MagnoliaHighlight };
  theme: string;
}

const Highlights = (props: Props): ReactElement => {
  const { title, highlights, highlight, theme, pageProps } = props;

  const renderNow = useDelayRender(200);
  const [selectedImageId, setSelectedImageId] = useState<string | undefined>(
    highlights?.[0]?.image?.assetId
  );
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.lg }, true);
  const { stateMap, toggle, setItem } = useTransitionMap<string>({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });
  const [transformedHighlights, setTransformedHighlights] = useState<ApiHighlight[]>([]);

  useEffect(() => {
    const localTransformedHighlights = !!highlight
      ? highlight['@nodes'].map(propertyName => {
          const magnoliaHighlight = highlight[propertyName as keyof Props['highlight']];
          return ApiHighlightFromJSON({
            id: magnoliaHighlight['@id'],
            text: magnoliaHighlight.text || '',
            image: !!magnoliaHighlight.image
              ? {
                  ...mapToApiImage(magnoliaHighlight.image),
                  id: magnoliaHighlight.image['@id'],
                  assetId: magnoliaHighlight.image['@id']
                }
              : undefined
          });
        })
      : !!highlights && highlights.length > 0
      ? highlights
      : [];
    setTransformedHighlights(localTransformedHighlights);

    if (!!localTransformedHighlights && localTransformedHighlights.length > 0) {
      localTransformedHighlights.forEach(highlight => {
        const id = highlight.image?.assetId;
        if (!!id) {
          setItem(id);
        }
      });

      const firstImageId = localTransformedHighlights?.[0]?.image?.assetId;
      if (!!firstImageId) {
        setSelectedImageId(firstImageId);
        toggle(firstImageId);
      }
    }
  }, [highlights, highlight]);

  const showImage = (e: MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    if (id !== selectedImageId) {
      setSelectedImageId(id);
      toggle(id);
    }
  };

  return (
    <GridContainer className="highlight">
      {isDesktop && renderNow ? (
        <>
          <h2 className="highlight__title">{title}</h2>
          <div className={`highlight__container ${theme || THEMES.sand}`}>
            <div className="highlight__container__inner">
              <div className="highlight__image__container">
                {transformedHighlights.length > 0 &&
                  transformedHighlights.map(highlight => (
                    <Fragment key={highlight.id}>
                      {!!highlight.image &&
                        stateMap.get(highlight.image.assetId || '')?.status !== 'unmounted' &&
                        stateMap.get(highlight.image.assetId || '')?.isMounted && (
                          <Image
                            image={highlight.image}
                            key={highlight.image.assetId}
                            className={`highlight__image${
                              stateMap.get(highlight.image.assetId || '')?.status !== 'preEnter' &&
                              stateMap.get(highlight.image.assetId || '')?.status !== 'exiting'
                                ? ' highlight__image--visible'
                                : ''
                            }${
                              stateMap.get(highlight?.image.assetId || '')?.status === 'exiting'
                                ? ' highlight__image--exiting'
                                : ''
                            }`}
                            width={768}
                            height={432}
                            aspectRatio="default"
                            serverSideContext={pageProps?.albConfig.context}
                          />
                        )}
                    </Fragment>
                  ))}
              </div>
              <div className="highlight__link__container">
                <ul className="highlight__link__list">
                  {transformedHighlights.length > 0 &&
                    transformedHighlights.map((highlight: ApiHighlight) => (
                      <li
                        key={highlight.id}
                        className={`highlight__link ${
                          highlight.image?.assetId === selectedImageId
                            ? ' highlight__link--active'
                            : ''
                        }`}
                      >
                        <a
                          href="#"
                          onClick={(e: MouseEvent<HTMLAnchorElement>) =>
                            showImage(e, highlight.image?.assetId || '')
                          }
                        >
                          {highlight.text}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={`highlight__container--mobile ${theme}`}>
          <h2 className="highlight__title">{title}</h2>
          {transformedHighlights.length > 0 &&
            transformedHighlights.map((highlight: ApiHighlight) => (
              <div key={highlight.id} className="w-100">
                <Image
                  image={highlight.image || {}}
                  className="highlight__image--mobile"
                  aspectRatio="default"
                  serverSideContext={pageProps?.albConfig.context}
                />
                <div className="highlight__text">{highlight.text}</div>
              </div>
            ))}
        </div>
      )}
    </GridContainer>
  );
};

export default Highlights;
