'use client';

import React, { ReactElement, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Next, Play, Previous } from '@/Theme/SVG/Svgs';
import IsoModal from '@/components/IsoModal';
import { useInView } from 'react-intersection-observer';
import { ApiVideoContent } from '../../../api/model';
import { Video } from '@/components/videoGallery/Video';
import { mapToApiImage } from '@/Util/globals';
import Image from '@/components/image/Image';
import { Props as PageProps } from '@/types/cms/magnolia';
import { getMagnoliaFocalUrl } from '@/Util/imageLoader';

interface Props {
  videos: ApiVideoContent[];
  pageProps?: PageProps;
}

const VideoGalleryInner = (props: Props): ReactElement => {
  const { videos, pageProps } = props;
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [modalVideo, setModalVideo] = useState<ApiVideoContent | null>(null);

  const [ref] = useInView({ threshold: 0.05 });

  return (
    <div ref={ref} className="video-gallery">
      <Carousel
        onChange={index => setActiveIndex(index)}
        selectedItem={activeIndex}
        showThumbs={false}
        showStatus={false}
        showIndicators={videos.length > 1}
        showArrows={videos.length > 1}
        renderArrowPrev={clickHandler =>
          activeIndex > 0 ? (
            <button
              onClick={clickHandler}
              className="nav_btn nav_btn_left  video-gallery__nav video-gallery__nav--prev"
            >
              <Previous />
            </button>
          ) : (
            <></>
          )
        }
        renderArrowNext={clickHandler =>
          activeIndex < videos.length - 1 ? (
            <button
              className="nav_btn nav_btn_right video-gallery__nav video-gallery__nav--next"
              onClick={clickHandler}
            >
              <Next />
            </button>
          ) : (
            <></>
          )
        }
        renderIndicator={(clickHandler, isSelected) => (
          <div
            className={`video-gallery__indicator${
              isSelected ? ' video-gallery__indicator--selected' : ''
            }`}
            onClick={clickHandler}
          />
        )}
      >
        {videos.map((video: ApiVideoContent) => {
          const preRollImage = mapToApiImage(video.preRollimage);
          return (
            <div key={video.videoContent?.name} className="position-relative">
              {!!preRollImage && !video.preRollvideo && (
                <Image image={preRollImage} fill serverSideContext={pageProps?.albConfig.context} />
              )}
              {!!video.preRollvideo && (
                <Video
                  videoSrc={video.preRollvideo.videoSrc || ''}
                  placeholderImageLink={
                    !!video.preRollvideo.placeholderImage
                      ? getMagnoliaFocalUrl(
                          'default',
                          pageProps?.albConfig?.context,
                          mapToApiImage(video.preRollvideo.placeholderImage)?.imageLink || '',
                          1280
                        )
                      : undefined
                  }
                  className="video-gallery__video"
                  preload={activeIndex > 0 ? 'none' : 'auto'}
                  // useManualLazyLoading
                  // loadManually={idx === activeIndex}
                  useLazyLoading
                />
              )}
              {!!video.videoContent && !video.preRollvideo && !preRollImage && (
                <Video
                  videoSrc={video.videoContent.videoSrc || ''}
                  backupVideoSrc={video.videoContent.backupVideoSrc}
                  placeholderImageLink={
                    !!video.videoContent.placeholderImage
                      ? getMagnoliaFocalUrl(
                          'default',
                          pageProps?.albConfig?.context,
                          mapToApiImage(video.videoContent.placeholderImage)?.imageLink || '',
                          1280
                        )
                      : undefined
                  }
                  className="video-gallery__video"
                  preload={activeIndex > 0 ? 'none' : 'auto'}
                  // useManualLazyLoading
                  // loadManually={idx === activeIndex}
                  useLazyLoading
                />
              )}
              <div className="video-gallery__overlay">
                {!!video.title && <h2 className="video-gallery__title">{video.title}</h2>}
                <button className="video-gallery__play" onClick={(): void => setModalVideo(video)}>
                  <Play />
                </button>
              </div>
            </div>
          );
        })}
      </Carousel>
      <IsoModal
        className="video-gallery__modal"
        toggle={(): void => setModalVideo(null)}
        isOpen={!!modalVideo}
        onClose={(): void => setModalVideo(null)}
      >
        {!!modalVideo && (
          <Video
            videoSrc={modalVideo.videoContent?.videoSrc || ''}
            backupVideoSrc={modalVideo.videoContent?.backupVideoSrc}
            placeholderImageLink={
              !!modalVideo.videoContent?.placeholderImage
                ? getMagnoliaFocalUrl(
                    'default',
                    pageProps?.albConfig?.context,
                    mapToApiImage(modalVideo.videoContent?.placeholderImage)?.imageLink || '',
                    1280
                  )
                : undefined
            }
            className="video-gallery__video__modal"
            fullPlayerMode
          />
        )}
      </IsoModal>
    </div>
  );
};

export default VideoGalleryInner;
