import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import Image from '@/components/image/Image';
import Keys from '@/Translations/generated/da/extensionTeaser.json.keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facClock, facCoinStack } from '@/Theme/SVG/Icons';
import Button from '@/components/Button';
import { useTranslation } from '@/app/i18n/client';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import OffcanvasContent from '@/components/extensions/OffcanvasContent';
import { getDefaultFormatPrice, THEMES } from '@/Util/globals';
import GridContainer from '@/Layouts/GridContainer';
import Offcanvas from '@/components/Offcanvas';
import { ApiExtension } from '../../../api/model';
import sanitize from '@/Util/sanitize';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { languageMapping } from '@/app/i18n/settings';
import { Props as PageProps } from '@/types/cms/magnolia';

interface Props {
  theme?: string;
  extensionTeaser?: ApiExtension[];
  pageProps?: PageProps;
}

interface TeaserState {
  isOpen: boolean;
}

const ExtensionTeaser = (props: Props): ReactElement => {
  const { extensionTeaser, theme, pageProps } = props;
  const language = useCurrentLanguage();
  const { t } = useTranslation('extensionTeaser');
  const { product } = useGlobalMGLProps() ?? {};

  const [teaserStates, setTeaserStates] = useState<TeaserState[]>([]);

  const toggleOffcanvas = useCallback((index: number) => {
    setTeaserStates(prevStates => {
      const updatedStates = [...prevStates];
      updatedStates[index] = { isOpen: !updatedStates[index]?.isOpen };
      return updatedStates;
    });
  }, []);

  return (
    <GridContainer className="extension-teaser">
      {product?.overviewHeadline && (
        <h2 className="extension-teaser__title">{product.overviewHeadline}</h2>
      )}
      <div>
        {extensionTeaser?.map((teaser: ApiExtension, index: number) => (
          <Fragment key={teaser.id}>
            {!!teaser && (
              <>
                <div className={`extension-teaser__container ${theme || THEMES.sand}`}>
                  <Image
                    image={teaser.mainImage || {}}
                    className="extension-teaser__image"
                    width={960}
                    height={275}
                    serverSideContext={pageProps?.albConfig.context}
                  />
                  <div className="d-md-flex">
                    <div className="extension-teaser__info-section">
                      <div className="extension-teaser__duration">
                        <div className="extension-teaser__icon">
                          <FontAwesomeIcon icon={facClock} />
                        </div>
                        <div>
                          {teaser.duration} {t(Keys.duration)}
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="extension-teaser__name">{teaser.name}</div>
                          <div className="extension-teaser__print">
                            <div className="extension-teaser__duration__print">
                              <div className="extension-teaser__icon">
                                <FontAwesomeIcon icon={facClock} />
                              </div>
                              <div>
                                {teaser.duration} {t(Keys.duration)}
                              </div>
                            </div>
                            <div className="extension-teaser__price__print">
                              <div className="extension-teaser__icon">
                                <FontAwesomeIcon icon={facCoinStack} />
                              </div>
                              <div>
                                {getDefaultFormatPrice(parseInt(teaser.price || '0', 10), language)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="extension-teaser__description">
                        {!!teaser.teaserDescription && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitize(teaser.teaserDescription, true)
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="extension-teaser__price-section d-md-block">
                      <div className="d-sm-flex d-md-block">
                        <div className="extension-teaser__price">
                          {getDefaultFormatPrice(parseInt(teaser.price || '0', 10), language)}
                        </div>
                      </div>
                      <div className="extension-teaser__button">
                        <Button color="primary" onClick={() => toggleOffcanvas(index)}>
                          {t(Keys.buttonText)}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Offcanvas
                    isOpen={teaserStates[index]?.isOpen || false}
                    toggle={(): void => toggleOffcanvas(index)}
                    className={`${teaserStates[index]?.isOpen ? 'opened' : ''} ${theme}`}
                  >
                    <OffcanvasContent teaser={teaser} pageProps={pageProps} />
                  </Offcanvas>
                </div>
              </>
            )}
          </Fragment>
        ))}
      </div>
    </GridContainer>
  );
};

export default ExtensionTeaser;
