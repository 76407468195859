import React, { FC, JSX, useContext, useEffect, useRef } from 'react';
import { ApiPrice } from '@ibe/api';
import { getPrice } from '@/Util/globals';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import classNames from 'classnames';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { ApiImage } from '../../../api/model';
import sanitize from '@/Util/sanitize';
import Image from '@/components/image/Image';
import { ScrollContainerContext } from '@/components/selectionItems/SelectionItems';
import { observer } from 'mobx-react';
import { Props } from '@/types/cms/magnolia';

const SelectionItem: FC<{
  itemId: string;
  selectedItemId?: string;
  cartSelectionId: string;
  isOnlyItem: boolean;
  componentId: string;
  checkoutStore: CheckoutStore;
  name: string;
  price?: ApiPrice;
  description?: string;
  image?: ApiImage;
  showImages?: boolean;
  selectExternalItem?: () => void;
  pageProps?: Props;
  totalNumberOfSelectableHotels?: number;
  handleHotelSelect?: () => void;
  isActiveHotel?: boolean;
  onRequest?: boolean;
}> = observer(function SelectionItem({
  itemId,
  selectedItemId,
  cartSelectionId,
  isOnlyItem,
  componentId,
  checkoutStore,
  name,
  price,
  description,
  image,
  showImages,
  selectExternalItem,
  pageProps,
  totalNumberOfSelectableHotels,
  handleHotelSelect,
  isActiveHotel,
  onRequest
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const language = useCurrentLanguage();
  const itemContainer = useRef<HTMLDivElement>(null);
  const scrollContainer = useContext(ScrollContainerContext);

  useEffect(() => {
    if (!!itemContainer.current && !!scrollContainer && itemId === selectedItemId) {
      const itemContainerOffsetRight =
        itemContainer.current.offsetLeft + itemContainer.current.offsetWidth;
      const scrollContainerScrollRight = scrollContainer.scrollLeft + scrollContainer.offsetWidth;
      if (scrollContainer.scrollLeft > itemContainer.current.offsetLeft) {
        scrollContainer.scrollLeft = itemContainer.current.offsetLeft;
      } else if (scrollContainerScrollRight < itemContainerOffsetRight) {
        scrollContainer.scrollLeft += itemContainerOffsetRight - scrollContainerScrollRight;
      }
    }
  }, [scrollContainer]);

  const handleSelect = async (): Promise<void> => {
    if (!!selectExternalItem) {
      selectExternalItem();
    } else {
      await checkoutStore.selectItemsInCart(componentId, [cartSelectionId]);
    }
  };

  return (
    <div ref={itemContainer} className="selection-item__outer">
      {!!image && showImages && (
        <div className="selection-item__image">
          <Image image={image} serverSideContext={pageProps?.albConfig.context} />
        </div>
      )}
      <div className="selection-item">
        <div className="selection-item__inner">
          <div className="selection-item__info">
            <div className="selection-item__name">{name}</div>
            <div className="selection-item__price">
              {!!price?.finalPrice && !!price?.currencyCode ? (
                getPrice(price, language)
              ) : (
                <span>{t(Keys.includedShort)}</span>
              )}
            </div>
            {onRequest && <div className="selection-item__onrequest">{t(Keys.onRequest)}</div>}
            {!!description && (
              <div
                className="selection-item__description"
                dangerouslySetInnerHTML={{ __html: sanitize(description, true) }}
              />
            )}
          </div>
          {!isOnlyItem && (
            <div className="selection-item__select">
              <button
                type="button"
                className={classNames('selection-item__select__button', {
                  'selection-item__select__button--active': itemId === selectedItemId
                })}
                onClick={handleSelect}
              >
                <span>{t(Keys.select)}</span>
                <div className="selection-item__select__button__radio" />
              </button>
            </div>
          )}
          {isOnlyItem && (totalNumberOfSelectableHotels || 0) > 1 && (
            <div className="selection-item__select">
              <button
                type="button"
                className={classNames('selection-item__select__button', {
                  'selection-item__select__button--active': isActiveHotel
                })}
                onClick={handleHotelSelect}
              >
                <span>{t(Keys.select)}</span>
                <div className="selection-item__select__button__radio" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default SelectionItem;
