import { makeAutoObservable } from 'mobx';
import {
  ApiAddItemRequest,
  ApiAddress,
  ApiBooking,
  ApiBookingCustomPropertyFromJSON,
  ApiChangeComponentsRequestFromJSON,
  ApiCommunicationDetails,
  ApiComponent,
  ApiComponentType,
  ApiExtraService,
  ApiFlightItem,
  ApiGender,
  ApiGetPackageCartStartOptionsFromJSON,
  ApiHotel,
  ApiInsurance,
  ApiItemType,
  ApiNote,
  ApiNoteFromJSON,
  ApiNoteType,
  ApiPackageCart,
  ApiPaymentOption,
  ApiPreparePaymentRequestFromJSON,
  ApiPriceModifierType,
  ApiProductsCacheData,
  ApiSignupRequestFromJSON,
  ApiTransfer,
  ApiTraveler,
  ApiTravelerFromJSON,
  ApiTravelerType,
  ApiUpdateNotesRequestFromJSON
} from '@ibe/api';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { ApiImage, ApiProduct } from '../../../api/model';
import { getParticipantCount, SelectedOptions } from '@/components/productSelection/optionsData';
import { ApiService } from '@ibe/services';
import { prepareCheckoutParamsData } from '@/components/ProductsDatesPrices/ProductsDatesPrices';
import {
  API_ITEM_SERVICE_CODE,
  CHECKOUT_FORM_NOTES_ADVERTISING_CODE,
  CHECKOUT_FORM_NOTES_MEDIA_CODE,
  CHECKOUT_PARAMS_PARAMETER,
  logger,
  NEWSLETTER_SOURCE,
  NOTES_TYPE_ADDITIONAL_INS,
  NOTES_TYPE_CANCEL_INS,
  NOTES_TYPE_CODE,
  PACKAGE_CART_ID_PARAMETER,
  sanitizeString
} from '@/Util/globals';
import dayjs from 'dayjs';
import { MagnoliaSite } from '@/types/cms/magnolia';
import { isExtraService, isTransfer } from '@/types/typeGuards';
import { FormDataType } from '@/components/checkout/ParticipantsForm';
import { BaseData } from '@/templates/checkout/useBackendData';
import { PacificProductForHeroDispatch } from '@/components/checkout/checkoutStepConfirmation/CheckoutStepConfirmation';
import { OnlinePaymentCallerProps } from '@/templates/checkout/CheckoutPageInnerMarkup';

export type ApiComponentAdvanced<T> = Omit<ApiComponent, 'selectableItems' | 'selectedItems'> & {
  selectableItems: T[];
  selectedItems: T[];
};

export type CheckoutStorage = { displayUrl: string; packageCartId: string; numberOfSteps: number };
export type CheckoutLocalStorage = {
  productName: string;
  productCode: string;
  productTeaserImage: ApiImage;
};
export const CHECKOUT_STORAGE_KEY = 'checkout-session-storage';
export const CHECKOUT_LOCAL_STORAGE_KEY = 'checkout-local-storage';

type MediaCode = {
  code: string;
  translated: string;
  additionalCode?: string;
  additionalCodeTranslated?: string;
};

type VoucherStatus = 'error' | 'notFound' | 'success' | 'alreadyExists' | 'nothing';

class CheckoutStore {
  private _packageCart?: ApiPackageCart;
  private _booking?: ApiBooking;
  private _pacificProducts: ApiProductsCacheData[] = [];
  private _selectedPacificProduct: ApiProductsCacheData;
  private _product: ApiProduct;
  private _selectedFilterOptions: SelectedOptions;
  private _baseData: BaseData;
  private _isLoading: boolean = false;
  private _errorMessage: string | null = null;
  private _termsAndConditionsChecked: boolean = false;
  private _termsAndConditionsNotCheckedError: boolean = false;
  private _mediaCode: MediaCode = {
    code: '',
    translated: '',
    additionalCode: '',
    additionalCodeTranslated: ''
  };
  private _mediaCodeError: boolean = false;
  private _voucherStatus: VoucherStatus = 'nothing';
  private _promoCodeStatus: VoucherStatus = 'nothing';
  public termsAndConditionsNotCheckedErrorId = 'terms-and-conditions-anchor-id';
  public mediaCodeErrorId = 'media-code-anchor-id';
  public mountParticipantsFormComponent = true;

  constructor(
    private _api: ApiService,
    private _window: Window | null | undefined,
    private _pathname: string,
    private _siteConfig: MagnoliaSite | undefined | null,
    private _locale: string
  ) {
    makeAutoObservable(this);
  }

  set packageCart(packageCart: ApiPackageCart | undefined) {
    this._packageCart = packageCart;
  }

  get packageCart(): ApiPackageCart | undefined {
    return this._packageCart;
  }

  set booking(booking: ApiBooking | undefined) {
    this._booking = booking;
  }

  get booking(): ApiBooking | undefined {
    return this._booking;
  }

  set errorMessage(errorMessage: string | null) {
    this._errorMessage = errorMessage;
  }

  get errorMessage(): string | null {
    return this._errorMessage;
  }

  set pacificProducts(pacificProducts: ApiProductsCacheData[]) {
    this._pacificProducts = pacificProducts;
  }

  get pacificProducts(): ApiProductsCacheData[] {
    return this._pacificProducts;
  }

  set selectedPacificProduct(selectedPacificProduct: ApiProductsCacheData) {
    this._selectedPacificProduct = selectedPacificProduct;
  }

  get selectedPacificProduct(): ApiProductsCacheData {
    return this._selectedPacificProduct;
  }

  set product(product: ApiProduct) {
    this._product = product;
  }

  get product() {
    return this._product;
  }

  setSelectedFilterOptions(
    selectedFilterOptions: SelectedOptions,
    setPacificProduct: PacificProductForHeroDispatch
  ) {
    this._selectedFilterOptions = selectedFilterOptions;
    (async () => await this.computeNewSelectedPacificProduct(setPacificProduct))();
  }

  get selectedFilterOptions(): SelectedOptions {
    return this._selectedFilterOptions;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  set mediaCode(mediaCode: MediaCode) {
    this._mediaCode = { ...mediaCode };
    this._mediaCodeError = false;
  }

  get mediaCode(): MediaCode {
    return this._mediaCode;
  }

  set mediaCodeError(error: boolean) {
    this._mediaCodeError = error;
    if (error) {
      const mediaCode = this._window?.document?.getElementById(
        this.mediaCodeErrorId
      ) as HTMLDivElement;
      if (!!mediaCode && !!this._window) {
        this._window.scroll({
          top: mediaCode.getBoundingClientRect().top + this._window.scrollY,
          behavior: 'smooth'
        });
      }
    }
  }

  get mediaCodeError() {
    return this._mediaCodeError;
  }

  get voucherStatus() {
    return this._voucherStatus;
  }

  set voucherStatus(voucherStatus: VoucherStatus) {
    this._voucherStatus = voucherStatus;
  }

  get promoCodeStatus() {
    return this._promoCodeStatus;
  }

  set promoCodeStatus(promoCodeStatus: VoucherStatus) {
    this._promoCodeStatus = promoCodeStatus;
  }

  get voucherModifier() {
    return this._booking?.price?.modifiers?.find(
      modifier => modifier.type === ApiPriceModifierType.DISCOUNT
    );
  }

  set termsAndConditionsChecked(checked: boolean) {
    this._termsAndConditionsChecked = checked;
    if (checked) {
      this.termsAndConditionsNotCheckedError = false;
    }
  }

  get termsAndConditionsChecked(): boolean {
    return this._termsAndConditionsChecked;
  }

  set termsAndConditionsNotCheckedError(error: boolean) {
    this._termsAndConditionsNotCheckedError = error;
    if (error) {
      const termsAndConditions = this._window?.document?.getElementById(
        this.termsAndConditionsNotCheckedErrorId
      ) as HTMLDivElement;
      if (!!termsAndConditions && !!this._window) {
        this._window.scroll({
          top: termsAndConditions.getBoundingClientRect().top + this._window.scrollY - 100,
          behavior: 'smooth'
        });
      }
    }
  }

  get termsAndConditionsNotCheckedError(): boolean {
    return this._termsAndConditionsNotCheckedError;
  }

  set baseData(baseData: BaseData) {
    this._baseData = baseData;
  }

  get baseData(): BaseData {
    return this._baseData;
  }

  get flights(): ApiFlightItem[] {
    return this._packageCart?.packageModel?.packageDetails?.[0]?.components
      ?.filter(
        item =>
          item.itemType === ApiItemType.FLIGHT &&
          item.componentType !== ApiComponentType.HIDDEN &&
          item.componentType === ApiComponentType.REQUIRED
      )
      ?.flatMap(component => component?.selectedItems) as ApiFlightItem[];
  }

  get hotels(): ApiComponentAdvanced<ApiHotel>[] {
    return (this._packageCart?.packageModel?.packageDetails?.[0]?.components?.filter(
      item =>
        item.itemType === ApiItemType.HOTEL &&
        item.componentType !== ApiComponentType.MAIN &&
        item.componentType !== ApiComponentType.HIDDEN &&
        item.selectableItems.some(
          selectable =>
            isExtraService(selectable) &&
            (selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.HOTEL ||
              selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.HOTEL_ALT1 ||
              selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.HOTEL_ALT2 ||
              selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.CRUISE)
        )
    ) || []) as ApiComponentAdvanced<ApiHotel>[];
  }

  get transfers(): ApiComponentAdvanced<ApiTransfer>[] {
    return (this._packageCart?.packageModel?.packageDetails?.[0]?.components?.filter(
      item =>
        item.itemType === ApiItemType.TRANSFER &&
        item.componentType !== ApiComponentType.HIDDEN &&
        (!isTransfer(item.selectedItems[0]) ||
          (isTransfer(item.selectableItems[0]) &&
            item.selectableItems[0].category.code !== API_ITEM_SERVICE_CODE.BU)) &&
        ((item.selectableItems.length === 1 &&
          !!(item.selectableItems[0] as ApiTransfer)?.units?.[0]?.price?.finalPrice) ||
          item.selectableItems.length > 1)
    ) || []) as ApiComponentAdvanced<ApiTransfer>[];
  }

  get bus(): ApiComponentAdvanced<ApiTransfer>[] {
    return (this._packageCart?.packageModel?.packageDetails?.[0]?.components?.filter(
      item =>
        item.itemType === ApiItemType.TRANSFER &&
        item.componentType !== ApiComponentType.HIDDEN &&
        (!isTransfer(item.selectableItems[0]) ||
          (isTransfer(item.selectableItems[0]) &&
            item.selectableItems[0].category.code === API_ITEM_SERVICE_CODE.BU))
    ) || []) as ApiComponentAdvanced<ApiTransfer>[];
  }

  get singleExcursions(): ApiComponentAdvanced<ApiExtraService>[] {
    return ((this._packageCart?.packageModel?.packageDetails?.[0]?.components.filter(
      item =>
        item.itemType === ApiItemType.EXTRA &&
        item.componentType !== ApiComponentType.HIDDEN &&
        item.selectableItems.some(
          selectable =>
            isExtraService(selectable) &&
            selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.SINGLE_EXCURSION &&
            selectable.extraUnits[0].unitCode === API_ITEM_SERVICE_CODE.EXTENSION_EXCURSION
        )
    ) || []) as unknown) as ApiComponentAdvanced<ApiExtraService>[];
  }

  get excursionPackages(): ApiComponentAdvanced<ApiExtraService>[] {
    return ((this._packageCart?.packageModel?.packageDetails?.[0]?.components.filter(
      item =>
        item.itemType === ApiItemType.EXTRA &&
        item.componentType !== ApiComponentType.HIDDEN &&
        item.selectableItems.some(
          selectable =>
            isExtraService(selectable) &&
            selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.EXCURSION_PACKAGE &&
            selectable.extraUnits[0].unitCode === API_ITEM_SERVICE_CODE.EXTENSION_EXCURSION_PACKAGE
        )
    ) || []) as unknown) as ApiComponentAdvanced<ApiExtraService>[];
  }

  get operas(): ApiComponentAdvanced<ApiHotel>[] {
    return (this._packageCart?.packageModel?.packageDetails?.[0]?.components?.filter(
      item =>
        item.itemType === ApiItemType.HOTEL &&
        item.componentType !== ApiComponentType.MAIN &&
        item.componentType !== ApiComponentType.HIDDEN &&
        item.selectableItems.some(
          selectable =>
            isExtraService(selectable) &&
            selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.OPERA
        )
    ) || []) as ApiComponentAdvanced<ApiHotel>[];
  }

  get mealPackages(): ApiComponentAdvanced<ApiExtraService>[] {
    return (this._packageCart?.packageModel?.packageDetails?.[0]?.components?.filter(
      item =>
        item.itemType === ApiItemType.EXTRA &&
        item.componentType !== ApiComponentType.HIDDEN &&
        item.selectableItems.some(
          selectable =>
            isExtraService(selectable) &&
            selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.MEAL
        )
    ) || []) as ApiComponentAdvanced<ApiExtraService>[];
  }

  get extras(): ApiComponentAdvanced<ApiExtraService>[] {
    return (this._packageCart?.packageModel?.packageDetails?.[0]?.components?.filter(
      item =>
        item.itemType === ApiItemType.EXTRA &&
        item.componentType !== ApiComponentType.HIDDEN &&
        item.selectableItems.some(
          selectable =>
            isExtraService(selectable) &&
            (selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.EXTRA ||
              selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.FO_EXTRA ||
              selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.TRF_EXTRA)
        )
    ) || []) as ApiComponentAdvanced<ApiExtraService>[];
  }

  get visa(): ApiComponentAdvanced<ApiExtraService> | undefined {
    const visa = (this._packageCart?.packageModel?.packageDetails?.[0]?.components?.filter(
      item =>
        item.itemType === ApiItemType.EXTRA &&
        item.componentType !== ApiComponentType.HIDDEN &&
        item.selectableItems.some(
          selectable =>
            isExtraService(selectable) &&
            selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.VISA
        )
    ) || []) as ApiComponentAdvanced<ApiExtraService>[];
    return !!visa && visa.length > 0 ? visa[0] : undefined;
  }

  get extensions(): ApiComponentAdvanced<ApiExtraService>[] {
    return ((this._packageCart?.packageModel?.packageDetails?.[0]?.components.filter(
      item =>
        item.itemType === ApiItemType.EXTRA &&
        item.componentType !== ApiComponentType.HIDDEN &&
        item.selectableItems.some(
          selectable =>
            isExtraService(selectable) &&
            (selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.EXTENSION_PACKAGE ||
              selectable?.serviceType?.code === API_ITEM_SERVICE_CODE.EXTENSION_PACKAGE_ALT1)
        )
    ) || []) as unknown) as ApiComponentAdvanced<ApiExtraService>[];
  }

  get insurance(): ApiComponentAdvanced<ApiInsurance> | undefined {
    const insurances = ((this._packageCart?.packageModel?.packageDetails?.[0]?.components.filter(
      item =>
        item.itemType === ApiItemType.INSURANCE &&
        item.componentType !== ApiComponentType.HIDDEN &&
        item.selectableItems.some(selectable => selectable.itemType === ApiItemType.INSURANCE)
    ) || []) as unknown) as ApiComponentAdvanced<ApiInsurance>[];
    if (insurances.length > 0) {
      return insurances.reduce((total, current, idx) => {
        return idx > 0
          ? {
              ...total,
              selectableItems: [
                {
                  ...total.selectableItems[0],
                  relatedServices: [
                    ...(total.selectableItems?.[0]?.relatedServices || []),
                    ...(current.selectableItems?.[0]?.relatedServices || [])
                  ]
                }
              ]
            }
          : total;
      }, insurances[0]);
    }
    return undefined;
  }

  updateHistoryStateAndDispatchNewPacificProduct(): void {
    const checkoutParamsData = prepareCheckoutParamsData(
      this._selectedPacificProduct.packageId || '',
      this._product?.displayUrl || '',
      this._product?.name || '',
      this._selectedPacificProduct.packageCode || '',
      '',
      this._window
    );
    if (!!checkoutParamsData) {
      const currentSearchParams = new URLSearchParams();
      currentSearchParams.set(CHECKOUT_PARAMS_PARAMETER, checkoutParamsData);
      if (!!this._packageCart?.id) {
        currentSearchParams.set(PACKAGE_CART_ID_PARAMETER, this._packageCart.id);
      }
      const newUrl = `${this._pathname}${
        !!currentSearchParams.toString() ? `?${currentSearchParams.toString()}` : ''
      }`;
      this._window?.history?.replaceState(
        { ...window?.history?.state, as: newUrl, url: newUrl },
        '',
        newUrl
      );
    }
  }

  async createTravelers(
    travelerForms: FormDataType[],
    doUpdateTravelers?: boolean
  ): Promise<{ travelers: ApiTraveler[]; emailsForNewsletter: string[] }> {
    const bookingTravelers = this._booking?.travelers;
    let emailsForNewsletter: string[] = [];
    const travelers = travelerForms
      .map((travelerForm: FormDataType, idx: number) => {
        const email = (travelerForm.email ||
          bookingTravelers?.[idx]?.communicationDetails?.email) as string;
        if (travelerForm.newsletter && !!email) {
          emailsForNewsletter = [...emailsForNewsletter, email];
        }
        return ApiTravelerFromJSON({
          ...bookingTravelers?.[idx],
          id: bookingTravelers?.[idx]?.id,
          salutation:
            travelerForm.title ||
            bookingTravelers?.[idx]?.salutation ||
            this._baseData?.salutations?.[this._baseData?.salutations?.length - 1 || 0]?.code,
          title:
            bookingTravelers?.[idx]?.title ||
            this._baseData?.salutations?.[this._baseData?.salutations?.length - 1 || 0]?.code,
          firstName: sanitizeString(
            travelerForm.firstName || bookingTravelers?.[idx]?.firstName || '/'
          ),
          middleName: sanitizeString(bookingTravelers?.[idx]?.middleName || '/'),
          lastName: sanitizeString(
            travelerForm.lastName || bookingTravelers?.[idx]?.lastName || '/'
          ),
          birthDate: travelerForm.birthDate || bookingTravelers?.[idx]?.birthDate || '/',
          age:
            !!travelerForm.birthDate || bookingTravelers?.[idx]?.birthDate
              ? dayjs().diff(
                  dayjs((travelerForm.birthDate as string) || bookingTravelers?.[idx]?.birthDate),
                  'year'
                )
              : 0,
          type: bookingTravelers?.[idx]?.type || ApiTravelerType.UNDEFINED,
          gender: bookingTravelers?.[idx]?.gender || ApiGender.OTHER,
          documentNumber: bookingTravelers?.[idx]?.documentNumber || null,
          bonusCardNumber: bookingTravelers?.[idx]?.bonusCardNumber || '/',
          communicationDetails: {
            ...bookingTravelers?.[idx]?.communicationDetails,
            email: sanitizeString(email || '/'),
            phone: `${sanitizeString(travelerForm.countryCode || '')}${sanitizeString(
              travelerForm.phone || bookingTravelers?.[idx]?.communicationDetails?.phone || '/'
            )}`
          },
          address: {
            ...bookingTravelers?.[idx]?.address,
            street: sanitizeString(
              travelerForm.street || bookingTravelers?.[idx]?.address?.street || '/'
            ),
            postalCode: sanitizeString(
              travelerForm.zipCode || bookingTravelers?.[idx]?.address?.postalCode || '/'
            ),
            city: sanitizeString(
              travelerForm.city || bookingTravelers?.[idx]?.address?.city || '/'
            ),
            countryCode: sanitizeString(
              travelerForm.country || bookingTravelers?.[idx]?.address?.countryCode || '/'
            )
          }
        });
      })
      .filter((_, idx) => !!bookingTravelers?.[idx]);
    if (doUpdateTravelers) {
      try {
        const booking = await this._api.updateTravelers(this._booking?.id || '', false, travelers);
        if (!!booking) {
          this._booking = booking;
        }
      } catch (e) {
        throw new Error(`Unable to update travelers: ${e}`);
      }
    }
    return { travelers, emailsForNewsletter };
  }

  async changeComponentsForInsurances(): Promise<void> {
    if (
      !!this.insurance?.id &&
      !!this._packageCart?.id &&
      this._packageCart?.packageModel?.packageDetails?.[0]?.id
    ) {
      try {
        const packageCart = await this._api.changeComponents(
          this._packageCart?.id,
          this._packageCart?.packageModel?.packageDetails?.[0]?.id,
          ApiChangeComponentsRequestFromJSON({
            startDate: this._packageCart.packageModel.packageDetails[0].startDate,
            checkComponentIds: [this.insurance.id],
            modifiedComponentId: this.insurance.id
          })
        );
        if (!!packageCart) {
          this._packageCart = packageCart;
        }
      } catch (e) {
        throw new Error(`Unable to change components for insurances: ${e}`);
      }
    }
  }

  async handleBooking(
    travelerForms: FormDataType[],
    useOnlinePayment: boolean,
    useDepositPayment: boolean,
    remarks?: FormDataType,
    onlinePaymentSettingState?: OnlinePaymentCallerProps
  ): Promise<string> {
    const { travelers, emailsForNewsletter } = await this.createTravelers(travelerForms);
    let rerouteUrl = '';
    if (emailsForNewsletter.length > 0) {
      try {
        await this._api.newsletterSignup(
          ApiSignupRequestFromJSON({
            emails: emailsForNewsletter,
            source: NEWSLETTER_SOURCE.checkout
          })
        );
      } catch (err) {
        logger('error')('Unable to send newsletter subscription', err);
      }
    }
    try {
      if (!!this._mediaCode) {
        await this._api.updateCustomProperties(this._booking?.id || '', [
          ApiBookingCustomPropertyFromJSON({
            propertyTypeCode: CHECKOUT_FORM_NOTES_MEDIA_CODE,
            value: this._mediaCode.translated
          }),
          ApiBookingCustomPropertyFromJSON({
            propertyTypeCode: CHECKOUT_FORM_NOTES_ADVERTISING_CODE,
            value: this._mediaCode.additionalCodeTranslated || ''
          })
        ]);
      }
      let notes: ApiNote[] = [];
      if (!!remarks?.notes) {
        notes.push(
          ApiNoteFromJSON({
            id: window?.btoa(JSON.stringify({ typeCode: NOTES_TYPE_CODE, position: '0' })),
            type: ApiNoteType.SPECIALREQUEST,
            text: sanitizeString(remarks.notes)
          })
        );
      }
      if (onlinePaymentSettingState?.cancel) {
        notes.push(
          ApiNoteFromJSON({
            id: window?.btoa(JSON.stringify({ typeCode: NOTES_TYPE_CANCEL_INS, position: '0' })),
            type: ApiNoteType.SPECIALREQUEST,
            text: 'Cancellation insurance requested'
          })
        );
      }
      if (onlinePaymentSettingState?.additional) {
        notes.push(
          ApiNoteFromJSON({
            id: window?.btoa(
              JSON.stringify({ typeCode: NOTES_TYPE_ADDITIONAL_INS, position: '0' })
            ),
            type: ApiNoteType.SPECIALREQUEST,
            text: 'Additional insurance requested'
          })
        );
      }
      if (notes.length > 0) {
        await this._api.updateNotes(
          this._booking?.id || '',
          ApiUpdateNotesRequestFromJSON({
            notes
          })
        );
      }
      await this._api.updateTravelers(this._booking?.id || '', false, travelers);
      rerouteUrl = await this._api.preparePayment(
        this._booking?.id || '',
        ApiPreparePaymentRequestFromJSON({
          isDeposit: useDepositPayment,
          paymentMethod: !useOnlinePayment ? ApiPaymentOption.INVOICE : ApiPaymentOption.CREDITCARD,
          amount: null,
          workflowType: 'PackageWorkflow'
        })
      );
    } catch (err) {
      logger('error')('Unable to perform booking: ', err);
      throw err;
    }
    return rerouteUrl;
  }

  async updateTravelers(
    singleTraveler?: ApiTraveler,
    participantIndex?: number,
    royalAlbNo?: string
  ): Promise<void> {
    const currentTravelers = this._booking?.travelers;
    if (!currentTravelers || !this._booking?.id) return;
    if (!!singleTraveler && participantIndex !== undefined) {
      currentTravelers[participantIndex] = {
        ...singleTraveler,
        id: currentTravelers?.[participantIndex]?.id || '',
        businessPartnerNumber: sanitizeString(royalAlbNo || '')
      };
    }
    // add dummy values to all empty properties of all travelers or backend will fail.
    // although this is really stupid and there should be a better way. let's see...
    const newTravelers: ApiTraveler[] = currentTravelers.map(traveler => {
      const newTraveler = Object.fromEntries(
        Object.entries(traveler).map(([key, value]) => [
          key,
          value ||
            (key === 'age'
              ? 0
              : key === 'birthDate'
              ? '0000-01-01'
              : key === 'gender'
              ? ApiGender.OTHER
              : key === 'nationality' ||
                key === 'countryOfIssue' ||
                key === 'businessPartnerNumber' ||
                key === 'address' ||
                key === 'communicationDetails'
              ? null
              : key === 'type'
              ? ApiTravelerType.ADULT
              : key === 'expirationDate'
              ? undefined
              : key === 'title' || key === 'salutation'
              ? this._baseData?.salutations?.[this._baseData?.salutations?.length - 1 || 0]?.code
              : '/')
        ])
      ) as ApiTraveler;
      return {
        ...newTraveler,
        communicationDetails: !!newTraveler.communicationDetails
          ? (Object.fromEntries(
              Object.entries({ ...newTraveler.communicationDetails }).map(([key, value]) => [
                key,
                value || '/'
              ])
            ) as ApiCommunicationDetails)
          : {},
        address: !!newTraveler.address
          ? ((Object.fromEntries(
              Object.entries({ ...newTraveler.address }).map(([key, value]) => [
                key,
                key === 'addressAdditionalInfo' ||
                key === 'region' ||
                key === 'regionCode' ||
                key === 'houseNumber'
                  ? null
                  : value || '/'
              ])
            ) as unknown) as ApiAddress)
          : undefined
      };
    });
    try {
      const booking = await this._api.updateTravelers(this._booking.id, false, newTravelers);
      if (!!booking) {
        this._booking = booking;
      }
    } catch (err) {
      logger('error')('Unable to update travelers: ', err);
    }
  }

  async fetchBooking(): Promise<ApiBooking | undefined> {
    let localBooking: ApiBooking | undefined = undefined;
    try {
      if (!!this.packageCart?.bookingId) {
        localBooking = await this._api.getBooking(this.packageCart.bookingId, false);
        this.booking = localBooking;
      }
    } catch (err) {
      logger('error')('Unable to fetch booking: ', err);
      this.errorMessage = Keys.bookingOrCartError;
    }
    return localBooking;
  }

  async fetchBookingFromExternalId(bookingId: string): Promise<ApiBooking | undefined> {
    let booking: ApiBooking | undefined = undefined;
    try {
      booking = await this._api.getBooking(bookingId, false);
    } catch (err) {
      logger('error')('Unable to fetch booking: ', err);
      this.errorMessage = Keys.bookingOrCartError;
    }
    return booking;
  }

  async removePackageCart(): Promise<void> {
    if (!!this._packageCart?.id) {
      try {
        await this._api.remove(this._packageCart.id);
        this.packageCart = undefined;
      } catch (err) {
        logger('error')('Unable to remove package cart', err);
        this.errorMessage = Keys.bookingOrCartError;
      }
    }
  }

  async startPackageCart(): Promise<void> {
    if (this._selectedPacificProduct.packageId) {
      try {
        this.packageCart = await this._api.start(
          this._selectedPacificProduct.packageId,
          'EMPTY',
          true,
          undefined,
          ApiGetPackageCartStartOptionsFromJSON({
            site: this._siteConfig?.name || '',
            language: this._locale
          })
        );
        await this.fetchBooking();
      } catch (err) {
        logger('error')('Unable to start package cart');
        this.errorMessage = Keys.bookingOrCartError;
        throw err;
      }
    }
  }

  async selectItemsInCart(
    componentId: string,
    selectedItemIds: string[],
    bookingOptions?: Record<string, ApiAddItemRequest>
  ): Promise<boolean> {
    if (!!this._packageCart?.id) {
      this.isLoading = true;
      try {
        this.packageCart = await this._api.selectItems(this._packageCart.id, componentId, {
          selectedItemIds,
          bookingOptions: bookingOptions || {}
        });
        await this.fetchBooking();
        return true;
      } catch (err) {
        logger('error')('Unable to select items in cart: ', err);
        this.errorMessage = Keys.bookingOrCartError;
        return false;
      } finally {
        this.isLoading = false;
      }
    }
    return false;
  }

  async deselectItemsInCart(componentId: string, deselectedItemIds: string[]): Promise<void> {
    if (!!this._packageCart?.id) {
      this.isLoading = true;
      try {
        this.packageCart = await this._api.removeItems(this._packageCart.id, componentId, {
          removedItemIds: deselectedItemIds
        });
        await this.fetchBooking();
      } catch (err) {
        logger('error')('Unable to deselect items in cart: ', err);
        this.errorMessage = Keys.bookingOrCartError;
      } finally {
        this.isLoading = false;
      }
    }
  }

  async addVoucherCode(voucherInput: string): Promise<void> {
    this.isLoading = true;
    try {
      if (!!voucherInput) {
        if (voucherInput === this.voucherModifier?.id) {
          this._voucherStatus = 'alreadyExists';
        } else {
          const booking = await this._api.addVoucherCodes(this._booking?.id || '', [voucherInput]);
          if (!!booking) {
            this._booking = booking;
            this._voucherStatus = !!this.voucherModifier ? 'success' : 'notFound';
          } else {
            this._voucherStatus = 'notFound';
          }
        }
      }
    } catch (err) {
      logger('error')('Unable to add voucher code: ', err);
      this._voucherStatus = 'error';
    } finally {
      this.isLoading = false;
    }
  }

  async addPromoCode(promoCodeInput: string): Promise<void> {
    this.isLoading = true;
    const { finalPrice: currentTotalDiscount } = this._booking?.totalDiscount || {};
    try {
      if (!!promoCodeInput) {
        const booking = await this._api.addPromoCodes(this._booking?.id || '', [promoCodeInput]);
        const { finalPrice: newTotalDiscount } = booking?.totalDiscount || {};
        if (!!booking) {
          this._booking = booking;
          this._promoCodeStatus =
            newTotalDiscount !== (currentTotalDiscount || 0) ? 'success' : 'notFound';
        } else {
          this._promoCodeStatus = 'notFound';
        }
      }
    } catch (err) {
      logger('error')('Unable to add voucher code: ', err);
      this._promoCodeStatus = 'error';
    } finally {
      this.isLoading = false;
    }
  }

  async computeNewSelectedPacificProduct(
    setPacificProduct: PacificProductForHeroDispatch
  ): Promise<void> {
    const newSelectedPacificProduct = this._pacificProducts?.find(
      pacificProduct =>
        getParticipantCount(pacificProduct) === this._selectedFilterOptions.participants &&
        pacificProduct.travelStartDate === this._selectedFilterOptions.departureDate &&
        (!pacificProduct.departureAirport ||
          pacificProduct.departureAirport === this._selectedFilterOptions.departureAirport)
    );
    if (
      !!newSelectedPacificProduct?.packageId &&
      (newSelectedPacificProduct.departureAirport !==
        this._selectedPacificProduct?.departureAirport ||
        newSelectedPacificProduct.travelStartDate !==
          this._selectedPacificProduct?.travelStartDate ||
        newSelectedPacificProduct.serviceUnitCode !== this._selectedPacificProduct?.serviceUnitCode)
    ) {
      this.isLoading = true;
      try {
        this.selectedPacificProduct = newSelectedPacificProduct;
        await this.removePackageCart();
        await this.startPackageCart();
        this.updateHistoryStateAndDispatchNewPacificProduct();
        setPacificProduct({ product: this.selectedPacificProduct, name: '' });
      } catch (err) {
        logger('error')(err);
      } finally {
        this.isLoading = false;
        this.mountParticipantsFormComponent = true;
      }
    }
  }

  hasInvalidFlights(flightsParameter?: ApiFlightItem[]): boolean {
    const flights = flightsParameter || this.flights;
    return !!flights && flights.length > 0
      ? flights
          .flatMap(flight => flight.segment)
          .filter(segment => {
            const departure = dayjs.utc(segment.departure);
            const arrival = dayjs.utc(segment.arrival);
            return (
              (!departure.isValid() ||
                (departure.isValid() && departure.hour() === 0 && departure.minute() === 0)) &&
              (!arrival.isValid() ||
                (arrival.isValid() && arrival.hour() === 0 && arrival.minute() === 0))
            );
          }).length > 0
      : true;
  }

  hasFlightsWithStops(): boolean {
    return !!this.flights && this.flights.length > 0
      ? this.flights
          .flatMap(flight => flight.segment)
          .filter(segment => !!segment.legs && segment.legs.length > 1).length > 0
      : false;
  }
}

export default CheckoutStore;
