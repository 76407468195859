'use client';

import React, { ReactElement, useEffect, useRef } from 'react';
import {
  EditableAreaOrPageChild,
  LinkSwitchableMixin,
  MagnoliaDamNode
} from '@/types/cms/magnolia';
import { useWindow } from '@ibe/components';
import Image from '@/components/image/Image';
import { mapToApiImage, THEMES } from '@/Util/globals';
import Link from '@/components/Link';
import GridContainer from '@/Layouts/GridContainer';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

interface Props extends Partial<EditableAreaOrPageChild> {
  description: string;
  image: MagnoliaDamNode;
  buttonText?: string;
  theme: string;
}

const HighlightBanner = (props: Props & LinkSwitchableMixin): ReactElement => {
  const { description, image, buttonText, theme, pageProps } = props;
  const imageRef = useRef<HTMLImageElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const window = useWindow();
  const linkAttr = getNextLinkAttrs(
    {
      linkTypepage: props.linkTypepage,
      linkType: props.linkType,
      linkTypecontinent: props.linkTypecontinent,
      linkTypecountry: props.linkTypecountry,
      linkTypeexternal: props.linkTypeexternal,
      linkTypecity: props.linkTypecity,
      linkTypetravelType: props.linkTypetravelType,
      linkTypedownload: props.linkTypedownload
    },
    useGlobalMGLProps()
  );

  useEffect(() => {
    const scroll = (): void => {
      if (!!imageRef.current) {
        const margin = imageRef.current.getBoundingClientRect().height * 0.47;
        imageRef.current.style.marginTop = `-${margin}px`;
        if (!!imageContainerRef.current) {
          imageContainerRef.current.style.marginTop = `${margin}px`;
        }
      }
    };

    window?.addEventListener('scroll', scroll);
    scroll();

    return () => {
      window?.removeEventListener('scroll', scroll);
    };
  }, [imageRef, imageRef.current, imageContainerRef, imageContainerRef.current]);

  return (
    <GridContainer className="highlight-banner">
      <div className="highlight-banner__container">
        <div className={`highlight-banner__container__inner ${theme || THEMES.sand}`} />
        <div className="highlight-banner__text__container">
          <div className="highlight-banner__text">{description}</div>
          {!!buttonText ? (
            <div className="highlight-banner__button">
              <Link
                showCaret
                className="btn btn-primary"
                href={linkAttr.href}
                target={linkAttr.target}
              >
                {buttonText}
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="highlight-banner__image__container">
          <div className="highlight-banner__image__container__inner">
            <Image
              image={mapToApiImage(image) || {}}
              className="highlight-banner__image"
              serverSideContext={pageProps?.albConfig.context}
              aspectRatio="4-3"
            />
          </div>
        </div>
      </div>
    </GridContainer>
  );
};

export default HighlightBanner;
