'use client';

import React, { FC, Ref, useMemo, useState } from 'react';
import { ImageProps } from 'next/image';
import NextImage from 'next/image';
import {
  AspectRatio,
  getDefaultImageLoader,
  getMagnoliaFocalImageLoader,
  getNoLoader
} from '@/Util/imageLoader';
import useConfig from '@/Hooks/useConfig';
import isClient from '@/Util/globals';
import classNames from 'classnames';
import { useProductPageContext } from '@/components/productPageContext/ProductPageContext';
import useDelayRender from '@/Hooks/useDelayRender';
import { ApiImage } from '../../../api/model';

export type ImageType = Omit<Partial<ImageProps>, 'loader'> & {
  aspectRatio?: AspectRatio;
  useDefaultLoader?: boolean;
  noLoader?: boolean;
  ref?: Ref<HTMLImageElement | null> | undefined;
  serverSideContext?: string;
  image: ApiImage;
  captionClassName?: string;
  showCaption?: boolean;
  ignoreFadeIn?: boolean;
};

const Image: FC<ImageType> = (props): JSX.Element => {
  const {
    serverSideContext,
    aspectRatio = 'default',
    useDefaultLoader,
    noLoader,
    ref,
    className,
    captionClassName,
    showCaption = false,
    ignoreFadeIn,
    priority,
    image,
    ...imageProps
  } = props;
  const { context } = useConfig();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { printMode } = useProductPageContext();
  const renderNow = useDelayRender(1);

  const loader = useMemo(() => {
    return noLoader
      ? getNoLoader()
      : useDefaultLoader
      ? getDefaultImageLoader(context)
      : getMagnoliaFocalImageLoader(aspectRatio, context);
  }, [aspectRatio, context, useDefaultLoader, noLoader]);

  const serverSideLoader =
    serverSideContext !== undefined
      ? noLoader
        ? getNoLoader()
        : useDefaultLoader
        ? getDefaultImageLoader(serverSideContext)
        : getMagnoliaFocalImageLoader(aspectRatio, serverSideContext)
      : undefined;

  const onLoad = () => {
    setIsLoaded(true);
  };

  return serverSideContext !== undefined || (isClient() && renderNow) ? (
    <>
      <NextImage
        {...imageProps}
        ref={ref}
        key={`${image?.id || ''}${image?.assetId || ''}${imageProps.src || ''}${
          image?.imageLink || ''
        }`}
        src={imageProps.src || image?.imageLink || ''}
        alt={imageProps.title || image?.title || ''}
        title={imageProps.title || image?.title}
        width={imageProps.width || image?.width}
        height={imageProps.height || image?.height}
        loader={serverSideLoader || loader}
        priority={priority}
        onLoad={onLoad}
        className={classNames(className || '', {
          'next-image': !ignoreFadeIn && !printMode,
          'next-image--visible': isLoaded && !ignoreFadeIn && !printMode
        })}
        loading={printMode || priority ? 'eager' : 'lazy'}
      />
      {image?.caption &&
        (showCaption ? (
          <figcaption className={'image__caption'}>
            <span>{image?.caption || ''}</span>
          </figcaption>
        ) : (
          <></>
        ))}
    </>
  ) : (
    <></>
  );
};

export default Image;
