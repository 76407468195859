import React, { FC, JSX, MouseEvent, useState } from 'react';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponseTravelTypes,
  ApiProductWithReviews
} from '@ibe/api';
import Image from '@/components/image/Image';
import { /* CircleHeart,*/ RatingStar } from '@/Theme/SVG/Svgs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCalendarSmall, facDatabase, facTime } from '@/Theme/SVG/Icons';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import dayjs from 'dayjs';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/search.json.keys';
import { DEPARTURE_DATES_ID } from '@/templates/ProductPageInner';
import classNames from 'classnames';
import { getDefaultFormatPrice, getLocalizedNumberFormat } from '@/Util/globals';
import { ApiImage } from '../../../api/model';
import { Link, useTransitionProgress } from '@/Hooks/useReactTransitionProgress';
import { useRouter } from 'next/navigation';
import { Props } from '@/types/cms/magnolia';

export const MAX_NUMBER_OF_SHOWN_TRAVELTYPES = 3;

const ResultsListItemContent: FC<{
  productPageBasePath: string;
  activeView: number;
  productDetailsLink: string;
  teaserImage?: ApiImage;
  map?: ApiImage;
  review?: ApiProductWithReviews;
  travelTypes?: ApiProductsCacheResponseTravelTypes[];
  name?: string;
  earliestProduct?: ApiProductsCacheData;
  hasMoreThanOneDate: boolean;
  displayUrl?: string;
  numberOfCacheProducts?: number;
  hasAvailableProduct?: boolean;
  cheapestProduct?: ApiProductsCacheData;
  pageProps?: Props;
}> = ({
  productPageBasePath,
  activeView,
  teaserImage,
  map,
  productDetailsLink,
  review,
  travelTypes,
  name,
  earliestProduct,
  hasMoreThanOneDate,
  displayUrl,
  numberOfCacheProducts,
  hasAvailableProduct,
  cheapestProduct,
  pageProps
}): JSX.Element => {
  const { t } = useTranslation('search');
  const router = useRouter();
  const config = useConfig();
  const language = useCurrentLanguage();
  const startTransition = useTransitionProgress();
  const [mouseOverActive, setMouseOverActive] = useState<boolean>(false);

  const moreThanOneDateClick = (e: MouseEvent<HTMLSpanElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    startTransition(() => {
      router.push(`/${productPageBasePath}/${displayUrl}#${DEPARTURE_DATES_ID}`);
    });
  };

  return (
    <Link className="results__list__item" href={productDetailsLink} prefetch={false}>
      <div
        className="results__list__item__image"
        onMouseEnter={(): void => setMouseOverActive(true)}
        onMouseLeave={(): void => setMouseOverActive(false)}
      >
        {!!teaserImage && (
          <Image
            image={teaserImage}
            height={(teaserImage.height || 0) > 212 ? 212 : teaserImage.height || 0}
            width={(teaserImage.width || 0) > 285 ? 285 : teaserImage.width || 0}
            aspectRatio="4-3"
            serverSideContext={pageProps?.albConfig.context}
          />
        )}
        {!!map && (
          <Image
            image={map}
            className={classNames('results__list__item__image__map', {
              'results__list__item__image__map--active': activeView === 1 || mouseOverActive
            })}
            height={(map.height || 0) > 212 ? 212 : map.height || 0}
            width={(map.width || 0) > 285 ? 285 : map.width || 0}
            aspectRatio="4-3"
            serverSideContext={pageProps?.albConfig.context}
          />
        )}
      </div>
      {/* TODO */}
      {/*<CircleHeart*/}
      {/*  className="results__list__item__favorite"*/}
      {/*  onClick={(e: MouseEvent<HTMLOrSVGElement>) => e.stopPropagation()}*/}
      {/*/>*/}
      {!!review?.averageRating && review.averageRating > -1 && (
        <div className="results__list__item__rating">
          <RatingStar mode="full" />
          <span>
            {getLocalizedNumberFormat(language)(parseFloat(review.averageRating.toFixed(1)))}
          </span>
        </div>
      )}
      <div className="results__list__item__content">
        <div>
          {!!travelTypes && travelTypes?.length > 0 && (
            <div className="results__list__item__travel-types">
              {`${(travelTypes.length > MAX_NUMBER_OF_SHOWN_TRAVELTYPES
                ? travelTypes.slice(0, MAX_NUMBER_OF_SHOWN_TRAVELTYPES)
                : travelTypes
              )
                .map(travelType => travelType.name)
                .join(', ')}${travelTypes.length > 3 ? ` +${travelTypes.length - 3}` : ''}`}
            </div>
          )}
          <div className="results__list__item__title">{name}</div>
        </div>
        <div>
          <hr className="results__list__item__delimiter" />
          <div className="results__list__item__cache">
            <div>
              <div className="results__list__item__cache__element">
                <FontAwesomeIcon icon={facCalendarSmall} />
                <div className="results__list__item__cache__element__inner">
                  <span>
                    {!!earliestProduct?.travelStartDate
                      ? dayjs(earliestProduct.travelStartDate).format(
                          config.displayFormatDate[language]
                        )
                      : dayjs().format(config.displayFormatDate[language])}
                  </span>
                  {hasMoreThanOneDate && (
                    <span
                      className="results__list__item__cache__element__more-than-one"
                      onClick={moreThanOneDateClick}
                    >{`${t(Keys.departureDates)} (${numberOfCacheProducts || 0})`}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-xs-flex mt-xs-2">
              <div className="results__list__item__cache__element">
                <FontAwesomeIcon icon={facTime} />
                <div>{t(Keys.day, { count: earliestProduct?.duration || 0 })}</div>
              </div>
              <div>|</div>
              <div
                className={classNames('results__list__item__cache__element', {
                  'results__list__item__cache__element--sold-out': !hasAvailableProduct
                })}
              >
                <FontAwesomeIcon icon={facDatabase} />
                <div>
                  {hasAvailableProduct
                    ? `${t(Keys.from)} ${getDefaultFormatPrice(
                        cheapestProduct?.startingPriceAmount || 0,
                        language
                      )}`
                    : t(Keys.soldOut)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ResultsListItemContent;
