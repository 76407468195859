'use client';

import { useEffect, useState } from 'react';
import { KeyPrefix } from 'i18next';
import { useTranslation as useReactTranslation, UseTranslationOptions } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { lngCookieName } from './settings';
import isClient from '@/Util/globals';

export const nameSpaces: string[] = [];

export const useTranslation = (ns: string, options?: UseTranslationOptions<KeyPrefix<any>>) => {
  const translationReturn = useReactTranslation(ns, options);
  const { i18n } = translationReturn;
  const { resolvedLanguage } = i18n;

  const [cookies] = useCookies([lngCookieName]);
  const [activeLng, setActiveLng] = useState(resolvedLanguage);
  const { [lngCookieName]: locale } = cookies;

  useEffect(() => {
    (async (): Promise<void> => {
      if (!nameSpaces.includes(ns)) {
        nameSpaces.push(ns);
      }
      if (!isClient() && !!locale && resolvedLanguage !== locale) {
        await i18n.changeLanguage(locale);
      } else {
        if (activeLng !== resolvedLanguage) {
          setActiveLng(resolvedLanguage);
        }
        if ((!!locale || resolvedLanguage !== locale) && !!i18n?.changeLanguage) {
          await i18n.changeLanguage(locale);
        }
      }
    })();
  }, [locale, i18n, resolvedLanguage, activeLng, locale]);

  return translationReturn;
};
